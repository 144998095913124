
import { defineComponent } from 'vue'
import UploadFileInput from './components/UploadFileInput.vue'
import UploadFileLog from './components/UploadFileLog.vue'

export default defineComponent({
  name: 'UploadFile',

  components: {
    UploadFileInput,
    UploadFileLog,
  },

  setup() {
    return {}
  },
})
