
import { defineComponent, ref } from 'vue'
import AppLogo from '@/components/AppLogo.vue'
import { useProfile } from '@/compositions/useProfile'
import AppNavigation from '@/components/AppNavigation.vue'
import AppConfirmSignOutModal from '@/components/AppConfirmSignOutModal.vue'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    AppLogo,
    AppNavigation,
    AppConfirmSignOutModal,
  },

  props: {
    meta: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const showConfirmSignOutModal = ref(false)
    const { profile } = useProfile()

    return { profile, showConfirmSignOutModal }
  },
})
