import { getRegistrations, RegistrationParams } from '@/api/report.api'
import { Registration } from '@/types/registration'
import { Ref, ref } from 'vue'

interface UseRegistration {
  fetchRegistrations: (dates: RegistrationParams) => Promise<void>
  registrations: Ref<Registration[]>
  registrationLoading: Ref<boolean>
  registrationErrorMessage: Ref<string | null>
}

const registrations = ref<Registration[]>([])

export const useRegistration = (): UseRegistration => {
  const registrationLoading = ref<boolean>(false)
  const registrationErrorMessage = ref<string | null>(null)

  async function fetchRegistrations(dates: RegistrationParams) {
    registrationErrorMessage.value = null
    registrationLoading.value = true
    try {
      const { data } = await getRegistrations(dates)
      registrations.value = data
    } catch (error) {
      console.log(error)
      registrationErrorMessage.value =
        'Ett fel inträffa, kunde inte hämta registreringar!'
      registrations.value = []
    }

    registrationLoading.value = false
  }

  return {
    fetchRegistrations,
    registrations,
    registrationLoading,
    registrationErrorMessage,
  }
}
