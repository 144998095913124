import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSvg = _resolveComponent("InlineSvg")!

  return (_openBlock(), _createBlock(_component_InlineSvg, {
    width: "32",
    height: "32",
    src: require('@/assets/logo.svg'),
    "aria-label": "ISS Fordonsvård"
  }, null, 8, ["src"]))
}