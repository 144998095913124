import { uploadFile as importFile, getImportLog } from '@/api/import.api'
import { ImportLog } from '@/types/import-log'
import { Ref, ref } from 'vue'

interface UseUploadFile {
  file: Ref<File | null>
  uploadFile: (file: File) => Promise<void>
  fileUploading: Ref<boolean>
  fileErrorMessage: Ref<string | null>
  fileSuccessMessage: Ref<string | null>
  log: Ref<ImportLog[]>
  logLoading: Ref<boolean>
  fetchImportLog: () => Promise<void>
}

const log = ref<ImportLog[]>([])

export const useUploadFile = (): UseUploadFile => {
  const file = ref<File | null>(null)
  const logLoading = ref<boolean>(false)
  const fileUploading = ref<boolean>(false)
  const fileErrorMessage = ref<string | null>(null)
  const fileSuccessMessage = ref<string | null>(null)

  async function uploadFile() {
    if (!file.value) return
    fileSuccessMessage.value = null
    fileErrorMessage.value = null
    fileUploading.value = true

    try {
      const formData = new FormData()
      formData.append('file', file.value)
      await importFile(formData)
      fileSuccessMessage.value = 'Filen är uppladdad!'
      file.value = null

      fetchImportLog()
    } catch (error: any) {
      console.dir(error)
      fileErrorMessage.value =
        error.response.data || `Ett fel inträffa: ${error.message}`
    }
    fileUploading.value = false
  }

  async function fetchImportLog() {
    logLoading.value = true
    try {
      const { data } = await getImportLog()
      log.value = data
    } catch (error) {
      console.dir(error)
      log.value = []
    }

    logLoading.value = false
  }

  return {
    log,
    file,
    uploadFile,
    logLoading,
    fetchImportLog,
    fileUploading,
    fileErrorMessage,
    fileSuccessMessage,
  }
}
