import { Ref, ref } from 'vue'
import { getProfile } from '@/api/profile.api'
import { Profile } from '@/types/profile'

interface UseProfile {
  profile: Ref<Profile | null>
  fetchProfile: () => Promise<Profile>
  hasPermission: (permissions: PERMISSION[] | PERMISSION) => boolean
  showPermissionModal: Ref<boolean>
}

export type PERMISSION =
  | 'mobile.view'
  | 'backoffice.view'
  | 'vehiclemaintenance.arrival.list'

const profile = ref<Profile | null>(null)
const showPermissionModal = ref<boolean>(false)

export const useProfile = (): UseProfile => {
  async function fetchProfile() {
    const { data } = await getProfile()
    profile.value = data
    return profile.value
  }

  function hasPermission(permissions: PERMISSION[] | PERMISSION) {
    if (!profile.value) return false

    const userPermissions: PERMISSION[] = profile.value.permissions.map(
      (permission) => permission.name
    )

    if (Array.isArray(permissions)) {
      return permissions.every((permission) =>
        userPermissions.includes(permission)
      )
    }

    return userPermissions.includes(permissions)
  }

  return { profile, fetchProfile, hasPermission, showPermissionModal }
}
