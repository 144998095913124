
import { defineComponent, PropType, ref } from 'vue'
import { useDeviation } from '@/compositions/useDeviation'
import { Deviation } from '@/types/deviation'

export default defineComponent({
  name: 'DeviationCheckDialog',

  emits: ['close'],

  props: {
    deviation: Object as PropType<Deviation>,
  },

  setup(props, { emit }) {
    const { deviationUpdateLoading, completeDeviation } = useDeviation()
    const completedDescription = ref('')

    async function onConfirm() {
      if (!props.deviation) return

      const parsedDeviationForm: Partial<Deviation> = {
        deviationTypeUuid: props.deviation.deviationTypeUuid,
        vehicleUuid: props.deviation.vehicleUuid,
        vehicleTypePlaceUuid: props.deviation.vehicleTypePlaceUuid,
        description: props.deviation.description,
      }

      await completeDeviation(props.deviation.uuid, {
        ...parsedDeviationForm,
        completed: new Date(),
        completedDescription: completedDescription.value || null,
      })
      emit('close')
    }

    return {
      onConfirm,
      completedDescription,
      deviationUpdateLoading,
    }
  },
})
