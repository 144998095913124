
import { defineComponent } from 'vue'
import UsersTable from './components/UsersTable.vue'

export default defineComponent({
  name: 'Users',

  components: { UsersTable },

  setup() {
    return {}
  },
})
