import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between full-width items-center" }
const _hoisted_2 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppConfirmSignOutModal = _resolveComponent("AppConfirmSignOutModal")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_AppNavigation = _resolveComponent("AppNavigation")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.showConfirmSignOutModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showConfirmSignOutModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.showConfirmSignOutModal)
          ? (_openBlock(), _createBlock(_component_AppConfirmSignOutModal, {
              key: 0,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showConfirmSignOutModal = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_header, { class: "bg-white text-black shadow-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_toolbar, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_AppLogo),
              _createElementVNode("div", null, [
                _createVNode(_component_q_toolbar_title, { class: "text-subtitle1" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.meta.header), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.profile?.email) + " ", 1),
                _createVNode(_component_q_btn, {
                  class: "q-ml-md",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showConfirmSignOutModal = true)),
                  flat: "",
                  round: "",
                  dense: "",
                  icon: "mdi-logout-variant"
                })
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_AppNavigation)
      ]),
      _: 1
    }),
    _createVNode(_component_q_page_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_page, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}