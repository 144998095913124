import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = { class: "col q-mb-sm" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col justify-end flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_slide_transition = _resolveComponent("q-slide-transition")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!

  return (_openBlock(), _createBlock(_component_q_form, {
    ref: "signInForm",
    onSubmit: _ctx.onSignIn
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_input, {
            modelValue: _ctx.model.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.email) = $event)),
            label: "E-mail",
            rules: [_ctx.requiredRule],
            clearable: ""
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "mdi-account" })
            ]),
            _: 1
          }, 8, ["modelValue", "rules"])
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_input, {
            modelValue: _ctx.model.password,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.password) = $event)),
            label: "Lösenord",
            type: !_ctx.showPassword ? 'password' : 'text',
            rules: [_ctx.requiredRule]
          }, {
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "mdi-lock" })
            ]),
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: _ctx.showPassword ? 'mdi-eye-off' : 'mdi-eye',
                class: "cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
              }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["modelValue", "type", "rules"])
        ])
      ]),
      _createVNode(_component_q_slide_transition, null, {
        default: _withCtx(() => [
          (_ctx.signInErrorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_banner, {
                    dense: "",
                    class: "text-white bg-red",
                    rounded: ""
                  }, {
                    avatar: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        size: "xs",
                        name: "mdi-alert-circle-outline",
                        color: "white"
                      })
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.signInErrorMessage), 1)
                    ]),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_q_btn, {
            color: "primary",
            label: "Logga in",
            type: "submit",
            loading: _ctx.signInLoading
          }, null, 8, ["loading"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}