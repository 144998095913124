
import AppPermissionModal from '@/components/AppPermissionModal.vue'
import { defineComponent } from 'vue'

import { useProfile } from '@/compositions/useProfile'

export default defineComponent({
  name: 'App',

  components: { AppPermissionModal },

  setup() {
    const { showPermissionModal } = useProfile()
    return {
      showPermissionModal,
    }
  },
})
