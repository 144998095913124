
import { useProfile } from '@/compositions/useProfile'
import { useUser } from '@/compositions/useUser'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const { showPermissionModal, profile } = useProfile()
    const { signOut } = useUser()
    function onSignOut() {
      showPermissionModal.value = false
      profile.value = null
      signOut()
    }
    return { showPermissionModal, onSignOut }
  },
})
