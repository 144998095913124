import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    title: "Logg",
    class: _normalizeClass([_ctx.$style.stickyHeader, "shadow-0"]),
    columns: _ctx.columns,
    rows: _ctx.rows,
    loading: _ctx.logLoading,
    "rows-per-page-options": [0],
    style: _normalizeStyle(_ctx.tableStyle),
    "hide-bottom": "",
    "row-key": "uuid"
  }, null, 8, ["class", "columns", "rows", "loading", "style"]))
}