
import { exportExcel, Sheet } from '@/services/export.service'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'AppExportExcelButton',

  props: {
    name: {
      type: String,
      required: true,
    },
    sheets: {
      type: Array as PropType<Sheet[]>,
      required: true,
    },
  },

  setup(props) {
    const loading = ref(false)
    async function onExport() {
      loading.value = true
      const exportData = {
        name: props.name,
        sheets: props.sheets,
      }
      exportExcel(exportData)

      loading.value = false
    }
    return { onExport, loading }
  },
})
