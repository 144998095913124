
import { computed, defineComponent, ref, watch } from 'vue'
import { useDeviation } from '@/compositions/useDeviation'
import { format, subMonths } from 'date-fns'
import AppExportExcelButton from '@/components/AppExportExcelButton.vue'
import DeviationDetailsModal from './DeviationDetailsModal.vue'
import { VehicleType } from '@/types/vehicle-type'
import { Deviation } from '@/types/deviation'

interface CustomDeviation extends Deviation {
  vehicleName: number
  vehicleType: VehicleType
  deviationTypeName: string
}

interface TableColumn {
  name: string
  label: string
  field: keyof CustomDeviation
  align: 'left' | 'right'
  sortable: boolean
  format?: (value: string) => string
}

interface TransformedDeviation extends Omit<CustomDeviation, 'photos'> {
  photos: string
  // completed: string | null
}

interface ExportData {
  [key: string]: unknown
}

export default defineComponent({
  name: 'DeviationsTable',

  components: {
    AppExportExcelButton,
    DeviationDetailsModal,
  },

  setup() {
    const filter = ref('')
    const { deviationsLoading, fetchDeviations, deviations } = useDeviation()
    const selectedDeviation = ref<Deviation | null>(null)
    const showDeviationDetailsModal = ref(false)
    const showDateDialog = ref(false)

    const date = ref({
      from: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
      to: format(new Date(), 'yyyy-MM-dd'),
    })

    const columns: TableColumn[] = [
      {
        name: 'vehicleName',
        label: 'Vagn',
        field: 'vehicleName',
        align: 'left',
        sortable: true,
      },
      {
        name: 'vehicleType',
        label: 'Typ',
        field: 'vehicleType',
        align: 'left',
        sortable: true,
      },
      {
        name: 'deviationTypeName',
        label: 'Kategori',
        field: 'deviationTypeName',
        align: 'left',
        sortable: true,
      },
      // {
      //   name: 'vehicleTypePlace',
      //   label: 'Plats',
      //   field: 'vehicleTypePlace',
      //   align: 'left',
      //   sortable: true,
      // },
      {
        name: 'description',
        label: 'Beskrivning',
        field: 'description',
        align: 'left',
        sortable: true,
      },
      {
        name: 'photos',
        label: 'Foto',
        field: 'photos',
        align: 'left',
        sortable: true,
      },
      // {
      //   name: 'completedDescription',
      //   label: 'Utförd beskrivning',
      //   field: 'completedDescription',
      //   align: 'left',
      //   sortable: true,
      // },
      {
        name: 'userEmail',
        label: 'Skapad av',
        field: 'userEmail',
        align: 'left',
        sortable: true,
      },
      {
        name: 'created',
        label: 'Skapad',
        field: 'created',
        align: 'left',
        sortable: true,
      },
      {
        name: 'completed',
        label: 'Utförd',
        field: 'completed',
        align: 'left',
        format: (v: string) =>
          v ? format(new Date(v), 'yyyy-MM-dd HH:mm') : '',
        sortable: true,
      },
    ]

    const dateString = computed(() => {
      if (!date.value) return ''
      if (date.value.from && date.value.to) {
        return `${date.value.from} - ${date.value.to}`
      }
      return date.value
    })

    watch(
      () => date.value,
      (value: null | string | { from: string; to: string }) => {
        if (!value) return
        if (typeof value === 'string') {
          return fetchDeviations({
            from: value,
            to: value,
          })
        }

        fetchDeviations(value)
      },
      {
        immediate: true,
      }
    )

    const tableStyle = computed(() => {
      return {
        height: 'calc(100vh - 100px)',
      }
    })

    const rows = computed(() => {
      if (!deviations.value) return []
      return deviations.value.map((deviation) => ({
        ...deviation,
        deviationTypeName: deviation.deviationType.name,
        vehicleName: deviation.vehicle.name,
        vehicleType: deviation.vehicle.type,
        photos: deviation.photos.length > 0 ? 'Ja' : '',
        created: format(new Date(deviation.created), 'yyyy-MM-dd HH:mm'),
      }))
    })

    const exportSheet = computed(() => {
      return [
        {
          name: 'Avvikelser',
          data: rows.value.map((row: TransformedDeviation) => {
            return columns.reduce<ExportData>((acc, col) => {
              acc[col.label] = row[col.field]
              return acc
            }, {})
          }),
        },
      ]
    })

    function onRowClick(e: Event, deviation: Deviation) {
      if (!deviations.value) return
      selectedDeviation.value =
        deviations.value.find(
          (_deviation) => _deviation.uuid === deviation.uuid
        ) || null
      showDeviationDetailsModal.value = true
    }

    function onDateModelChange(
      v: { from: string; to: string } | null | string
    ) {
      if (!v) return

      showDateDialog.value = false
    }

    return {
      selectedDeviation,
      showDeviationDetailsModal,
      onRowClick,
      deviationsLoading,
      columns,
      rows,
      tableStyle,
      filter,
      exportSheet,
      dateString,
      date,
      showDateDialog,
      onDateModelChange,
    }
  },
})
