import { instance } from '@/services/iss-nattag-api.service'
import { AxiosResponse } from 'axios'
import { Registration } from '@/types/registration'

const URL = 'report'

export interface RegistrationParams {
  from: string
  to: string
}

export const getRegistrations = (
  registrationParams: RegistrationParams
): Promise<AxiosResponse<Registration[]>> =>
  instance.get(`${URL}/registration`, {
    params: registrationParams,
  })
