
import { defineComponent, PropType } from 'vue'
import { Registration } from '@/types/registration'

export default defineComponent({
  name: 'RegistrationsTrainDetailsModal',

  emits: ['close'],

  props: {
    registration: Object as PropType<Registration>,
  },

  setup(props, { emit }) {
    function close() {
      emit('close')
    }

    return {
      close,
    }
  },
})
