
import { computed, defineComponent, ref } from 'vue'
import { format } from 'date-fns'
import UsersFormModal from './UsersFormModal.vue'
import UsersRemoveModal from './UsersRemoveModal.vue'
import { useUserMasterdata } from '@/compositions/userUserMasterdata'
import { User } from '@/types/user'
import { Role } from '@/types/role'
import QTable from 'quasar/src/components/table/QTable.js';
import { getRoles } from '@/api/role.api'

export default defineComponent({
  name: 'UsersTable',

  components: {
    UsersFormModal,
    UsersRemoveModal,
  },

  setup() {
    const filter = ref('')
    const { data, getAll, loading } = useUserMasterdata()
    const showUsersFormModal = ref(false)
    const showUserRemoveModal = ref(false)
    const selectedUser = ref<User | null>(null)
    const mode = ref<'ADD' | 'EDIT'>('ADD')
    const roles = ref<Role[]>([])

    async function init() {
      getAll()
      const { data } = await getRoles()
      roles.value = data
    }

    init()

    const columns: QTable['columns'] = [
      {
        name: 'email',
        label: 'Email',
        field: 'email',
        align: 'left',
        sortable: true,
      },

      {
        name: 'roles',
        label: 'Roller',
        field: 'roles',
        align: 'left',
        format: (x: Role[]) => `${x.length}`,
        sortable: true,
      },

      {
        name: 'lastSignedIn',
        label: 'Senast inloggad',
        field: 'lastSignedIn',
        align: 'left',
        format: (x: string | null) =>
          x && format(new Date(x), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },

      {
        name: 'created',
        label: 'Skapad',
        field: 'created',
        align: 'left',
        format: (x: string) => format(new Date(x), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },

      {
        name: '_actions',
        label: '',
        field: '_actions',
        align: 'right',
      },
    ]

    const actionItems = [
      // {
      //   label: 'Redigera',
      //   action: (user: User) => {
      //     mode.value = 'EDIT'
      //     selectedUser.value = user
      //     showUsersFormModal.value = true
      //   },
      // },
      {
        label: 'Ta bort',
        action: (user: User) => {
          selectedUser.value = user
          showUserRemoveModal.value = true
        },
      },
    ]

    const tableStyle = computed(() => {
      return {
        height: 'calc(100vh - 100px)',
      }
    })

    const rows = computed(() => {
      return data.value
    })

    function onShowAddUserModal() {
      mode.value = 'ADD'
      showUsersFormModal.value = true
    }

    return {
      showUsersFormModal,
      columns,
      rows,
      tableStyle,
      filter,
      loading,
      selectedUser,
      actionItems,
      onShowAddUserModal,
      mode,
      roles,
      showUserRemoveModal,
    }
  },
})
