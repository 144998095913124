
import { computed, defineComponent, PropType, ref } from 'vue'
import { User } from '@/types/user'
import { v4 as uuidv4 } from 'uuid'
import {
  email,
  requiredArray,
  minCharactersRule,
  maxCharactersRule,
} from '@/common/formValidationRules'
import QForm from 'quasar/src/components/form/QForm.js';
import { Role } from '@/types/role'
import { useUserMasterdata } from '@/compositions/userUserMasterdata'

export default defineComponent({
  name: 'UsersFormModal',

  emits: ['close'],

  props: {
    user: Object as PropType<User | null>,
    mode: String as PropType<'ADD' | 'EDIT'>,
    roles: Array as PropType<Role[]>,
  },

  setup(props, { emit }) {
    const formRef = ref<QForm | null>(null)
    const { createing, createUser } = useUserMasterdata()

    const model = ref<{
      email: string
      uuid: string
      password: string
      roles: string[]
    }>({
      uuid: uuidv4(),
      email: '',
      password: '',
      roles: [],
    })

    const loading = computed(() => {
      return createing.value
    })

    if (props.mode === 'EDIT' && props.user) {
      const user = Object.assign({}, props.user)
      model.value.email = user.email
      model.value.roles = user.roles.map((x) => x.roleUuid)
    }

    function close() {
      emit('close')
    }

    async function onConfirm() {
      const isValid = await formRef.value?.validate()
      if (!isValid) return

      if (props.mode === 'ADD') {
        await createUser(model.value)
      }
      close()
    }

    return {
      model,
      formRef,
      close,
      onConfirm,
      email,
      minCharactersRule,
      maxCharactersRule,
      requiredArray,
      loading,
    }
  },
})
