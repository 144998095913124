import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    style: {"width":"120px"},
    class: "q-ml-md",
    onClick: _ctx.onExport,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createTextVNode("Exportera")
    ]),
    _: 1
  }, 8, ["onClick", "loading"]))
}