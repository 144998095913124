
import { defineComponent } from 'vue'
import SignInForm from './components/SignInForm.vue'
import AppLogo from '@/components/AppLogo.vue'

export default defineComponent({
  name: 'SignIn',

  components: {
    SignInForm,
    AppLogo,
  },
})
