import { instance } from '@/services/iss-nattag-api.service'
import { ImportLog } from '@/types/import-log'
import { AxiosResponse } from 'axios'

const URL = 'import'

export const uploadFile = (formData: FormData): Promise<AxiosResponse<void>> =>
  instance.post(`${URL}/train`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const getImportLog = (): Promise<AxiosResponse<ImportLog[]>> =>
  instance.get(`${URL}/train`)
