
import { defineComponent } from 'vue'
import { useUploadFile } from '@/compositions/useUploadFile'

export default defineComponent({
  name: 'UploadFileInput',
  setup() {
    return useUploadFile()
  },
})
