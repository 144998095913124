
import { defineComponent } from 'vue'
import RegistrationsTable from './components/RegistrationsTable.vue'

export default defineComponent({
  name: 'Registrations',

  components: { RegistrationsTable },

  setup() {
    return {}
  },
})
