
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  name: 'AppNavigation',

  setup() {
    const route = useRoute()
    const router = useRouter()
    const activeTab = ref(route.name)

    function goTo(path: string) {
      router.push(path)
    }

    const tabs = [
      {
        name: 'registrations',
        label: 'Registreringar',
        action: () => goTo('/registrations'),
      },
      {
        name: 'deviations',
        label: 'Avvikelser',
        action: () => goTo('/deviations'),
      },
      {
        name: 'upload-file',
        label: 'Ladda upp fil',
        action: () => goTo('/upload-file'),
      },
      {
        name: 'users',
        label: 'Användare',
        action: () => goTo('/users'),
      },
    ]

    return { activeTab, tabs }
  },
})
