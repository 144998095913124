import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppPermissionModal = _resolveComponent("AppPermissionModal")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh lpr lFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_dialog, {
        modelValue: _ctx.showPermissionModal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPermissionModal) = $event)),
        persistent: ""
      }, {
        default: _withCtx(() => [
          (_ctx.showPermissionModal)
            ? (_openBlock(), _createBlock(_component_AppPermissionModal, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            "enter-active-class": "animated animate__fadeIn",
            "leave-active-class": "animated animate__fadeOut",
            mode: "out-in",
            duration: 100
          }, {
            default: _withCtx(() => [
              (route.meta.layout)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(route.meta.layout), {
                    meta: route.meta,
                    key: route.path
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2
                  }, 1032, ["meta"]))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: route.path
                  }))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}