import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md flex justify-start full-width" }
const _hoisted_2 = { class: "q-pa-md flex justify-start" }
const _hoisted_3 = {
  class: "text-h6 q-mb-xs",
  style: {"line-height":"1"}
}
const _hoisted_4 = {
  class: "text-subtitle1 text-grey-6 flex items-center",
  style: {"line-height":"1"}
}
const _hoisted_5 = { class: "text-subtitle2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { style: {"max-width":"1000px","min-width":"400px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, { class: "row items-center" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_3, " Tåg " + _toDisplayString(_ctx.registration.train), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.registration.place) + " ", 1),
                  _createVNode(_component_q_icon, {
                    name: "mdi-arrow-right",
                    class: "q-px-xs"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.registration.arrival), 1)
                ])
              ]),
              _createVNode(_component_q_separator, {
                vertical: "",
                class: "q-ml-md q-mr-md"
              }),
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.registration.vehicleType) + " - " + _toDisplayString(_ctx.registration.vehicle), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_actions, { align: "right" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            flat: "",
            label: "Stäng",
            color: "primary",
            onClick: _ctx.close
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}