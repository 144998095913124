import axios from 'axios'
import { useUser } from '@/compositions/useUser'
const { getToken } = useUser()
const BASE_URL = 'https://nattag-api.iss-app.se'

export const instance = axios.create({
  baseURL: BASE_URL,
})

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`
  return config
})
