
import { defineComponent, PropType } from 'vue'
import { User } from '@/types/user'
import { useUserMasterdata } from '@/compositions/userUserMasterdata'

export default defineComponent({
  name: 'UsersRemoveModal',

  emits: ['close'],

  props: {
    user: Object as PropType<User | null>,
  },

  setup(props, { emit }) {
    const { deleteUser, deleteing } = useUserMasterdata()

    function close() {
      emit('close')
    }

    async function onConfirm() {
      if (!props.user) return
      await deleteUser(props.user.uuid)
      close()
    }

    return {
      deleteUser,
      deleteing,
      close,
      onConfirm,
    }
  },
})
