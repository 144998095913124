import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegistrationsTrainDetailsModal = _resolveComponent("RegistrationsTrainDetailsModal")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_AppExportExcelButton = _resolveComponent("AppExportExcelButton")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.showDetailsModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDetailsModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.showDetailsModal)
          ? (_openBlock(), _createBlock(_component_RegistrationsTrainDetailsModal, {
              key: 0,
              registration: _ctx.selectedRegistration,
              onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDetailsModal = false))
            }, null, 8, ["registration"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_table, {
      class: _normalizeClass([_ctx.$style.stickyHeader, "shadow-0"]),
      columns: _ctx.columns,
      rows: _ctx.rows,
      sort: "",
      loading: _ctx.registrationLoading,
      "rows-per-page-options": [0],
      style: _normalizeStyle(_ctx.tableStyle),
      filter: _ctx.filter,
      "hide-bottom": "",
      "row-key": "uuid"
    }, {
      "body-cell-status": _withCtx((props) => [
        _createVNode(_component_q_td, { props: props }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_q_badge, {
                color: props.value.color,
                label: props.value.label
              }, null, 8, ["color", "label"])
            ])
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      "top-left": _withCtx(() => [
        _createVNode(_component_q_input, {
          "model-value": _ctx.dateString,
          readonly: "",
          dense: "",
          style: {"width":"200px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_popup_proxy, {
              "transition-show": "scale",
              "transition-hide": "scale",
              modelValue: _ctx.showDateDialog,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDateDialog) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_date, {
                  mask: "YYYY-MM-DD",
                  modelValue: _ctx.date,
                  "onUpdate:modelValue": [
                    _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
                    _ctx.onDateModelChange
                  ],
                  range: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          prepend: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "mdi-calendar",
              class: "cursor-pointer"
            })
          ]),
          _: 1
        }, 8, ["model-value"])
      ]),
      "top-right": _withCtx(() => [
        _createVNode(_component_q_input, {
          dense: "",
          debounce: "300",
          modelValue: _ctx.filter,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter) = $event)),
          placeholder: "Sök..."
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, { name: "mdi-magnify" })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_AppExportExcelButton, {
          name: "Ankomster",
          sheets: _ctx.exportSheet,
          class: "q-ml-md"
        }, null, 8, ["sheets"])
      ]),
      _: 1
    }, 8, ["class", "columns", "rows", "loading", "style", "filter"])
  ], 64))
}