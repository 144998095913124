
import { useProfile } from '@/compositions/useProfile'
import { useUser } from '@/compositions/useUser'
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['close'],

  setup(_, { emit }) {
    const { profile } = useProfile()
    const { signOut, signOutLoading } = useUser()

    async function onSignOut() {
      profile.value = null
      await signOut()
      close()
    }

    function close() {
      emit('close')
    }

    return { onSignOut, close, signOutLoading }
  },
})
