
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, reactive, ref } from 'vue'
import { useUser } from '@/compositions/useUser'
import { useRouter } from 'vue-router'
import { required as requiredRule } from '@/common/formValidationRules'

export default defineComponent({
  name: 'SignInForm',

  setup() {
    const { signIn, signInErrorMessage, signInLoading } = useUser()
    const router = useRouter()
    const signInForm = ref<QForm | null>(null)
    const showPassword = ref(false)

    const model = reactive({
      email: '',
      password: '',
    })

    const onSignIn = async () => {
      const isValid = await signInForm.value?.validate()
      if (!isValid) return
      try {
        await signIn(model)
        router.push('/')
      } catch (error) {
        console.log(error)
      }
    }

    return {
      onSignIn,
      signInForm,
      signInErrorMessage,
      signInLoading,
      model,
      requiredRule,
      showPassword,
    }
  },
})
