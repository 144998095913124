import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { Component } from 'vue'
import { useUser } from '@/compositions/useUser'
import { useProfile, PERMISSION } from '@/compositions/useProfile'
import SignIn from '@/views/sign-in/SignIn.vue'
import Registrations from '@/views/registrations/Registrations.vue'
import Deviations from '@/views/deviations/Deviations.vue'
import UploadFile from '@/views/upload-file/UploadFile.vue'
import Users from '@/views/users/Users.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'

declare module 'vue-router' {
  interface RouteMeta {
    permissions?: PERMISSION[]
    layout?: Component
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/Registrations',
    alias: '/',
    name: 'registrations',
    component: Registrations,
    meta: {
      permissions: ['vehiclemaintenance.arrival.list'],
      layout: LayoutDefault,
    },
  },
  {
    path: '/deviations',
    name: 'deviations',
    component: Deviations,
    meta: {
      layout: LayoutDefault,
    },
  },
  {
    path: '/upload-file',
    name: 'upload-file',
    component: UploadFile,
    meta: {
      layout: LayoutDefault,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      layout: LayoutDefault,
    },
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  const { checkSignedIn, signOut } = useUser()
  const { fetchProfile, profile, hasPermission, showPermissionModal } =
    useProfile()
  const isSignedIn = await checkSignedIn()

  if (isSignedIn && !profile.value) {
    await fetchProfile()
  }

  const permissions: PERMISSION[] = to.meta?.permissions || []
  if (
    isSignedIn &&
    to.path !== '/sign-in' &&
    !hasPermission([...permissions, 'backoffice.view'])
  ) {
    showPermissionModal.value = true
    profile.value = null
    signOut()
    return '/sign-in'
  }
  if (to.path === '/sign-in' && isSignedIn) {
    return from.path
  }

  if (to.path !== '/sign-in' && !isSignedIn) {
    return '/sign-in'
  }
})

export default router
