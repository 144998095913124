import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!

  return (_openBlock(), _createBlock(_component_q_toolbar, { class: "q-pl-none" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_tabs, {
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
        "no-caps": "",
        class: "bg-white text-primary"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createBlock(_component_q_tab, {
              onClick: tab.action,
              name: tab.name,
              label: tab.label,
              key: tab.name
            }, null, 8, ["onClick", "name", "label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}