import { getDeviations, updateDeviation } from '@/api/deviation.api'
import { Deviation } from '@/types/deviation'
import { Ref, ref } from 'vue'

interface UseDeviation {
  fetchDeviations: (period: { from: string; to: string }) => Promise<void>
  deviations: Ref<Deviation[] | null>
  deviationsLoading: Ref<boolean>
  deviationsErrorMessage: Ref<string | null>
  deviationUpdateLoading: Ref<boolean>
  completeDeviation: (
    deviationUuid: string,
    deviationUpdateForm: Partial<Deviation>
  ) => Promise<void>
}

const deviations = ref<Deviation[] | null>(null)

export const useDeviation = (): UseDeviation => {
  const deviationsLoading = ref<boolean>(false)
  const deviationsErrorMessage = ref<string | null>(null)
  const deviationUpdateLoading = ref<boolean>(false)

  async function fetchDeviations(period: { from: string; to: string }) {
    deviationsErrorMessage.value = null
    deviationsLoading.value = true

    try {
      const { data } = await getDeviations(period)
      deviations.value = data
    } catch (error) {
      console.log(error)
      deviationsErrorMessage.value =
        'Ett fel inträffa, kunde inte hämta avvikelserna!'
      deviations.value = null
    }

    deviationsLoading.value = false
  }

  async function completeDeviation(
    deviationUuid: string,
    completedDeviationForm: Partial<Deviation>
  ) {
    deviationUpdateLoading.value = true
    await new Promise((resolve) => {
      updateDeviation(deviationUuid, completedDeviationForm)
        .then(() => {
          if (deviations.value) {
            deviations.value = deviations.value.filter(
              (x) => x.uuid !== deviationUuid
            )
          }
        })
        .catch((error) => {
          console.log(error)
          resolve([null, error])
        })
        .finally(() => {
          deviationUpdateLoading.value = false
          resolve([true, null])
        })
    })
  }

  return {
    fetchDeviations,
    deviations,
    deviationsLoading,
    deviationsErrorMessage,
    completeDeviation,
    deviationUpdateLoading,
  }
}
