
import { computed, defineComponent, ref, watch } from 'vue'
import { format, addDays, subDays } from 'date-fns'
import { useRegistration } from '@/compositions/useRegistration'
import AppExportExcelButton from '@/components/AppExportExcelButton.vue'
import { Registration } from '@/types/registration'
import RegistrationsTrainDetailsModal from './RegistrationsTrainDetailsModal.vue'
import orderBy from 'lodash.orderby'

interface TableColumn {
  name: string
  label: string
  field: keyof Registration
  align: 'left' | 'right'
  sortable: boolean
}

interface TransformedRegistration
  extends Omit<Registration, 'arrival' | 'performedAt'> {
  arrival: string
  performedAt: string
}

interface ExportData {
  [key: string]: unknown
}

export default defineComponent({
  name: 'RegistrationsTable',

  components: {
    AppExportExcelButton,
    RegistrationsTrainDetailsModal,
  },

  setup() {
    const selectedRegistration = ref<Registration | null>(null)
    const showDetailsModal = ref(false)
    const date = ref({
      from: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      to: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    })

    const filter = ref('')
    const showDateDialog = ref(false)
    const { registrationLoading, fetchRegistrations, registrations } =
      useRegistration()

    const columns: TableColumn[] = [
      {
        name: 'date',
        label: 'Datum',
        field: 'date',
        align: 'left',
        sortable: true,
      },
      {
        name: 'arrival',
        label: 'Ankomst',
        field: 'arrival',
        align: 'left',
        sortable: true,
      },
      {
        name: 'train',
        label: 'Tågnummer',
        field: 'train',
        align: 'left',
        sortable: true,
      },
      {
        name: 'place',
        label: 'Plats',
        field: 'place',
        align: 'left',
        sortable: true,
      },
      {
        name: 'activity',
        label: 'Aktivitet',
        field: 'activity',
        align: 'left',
        sortable: true,
      },
      {
        name: 'latestDelay',
        label: 'Försening (min)',
        field: 'latestDelay',
        align: 'left',
        sortable: true,
      },
      {
        name: 'latestDelayPlace',
        label: 'Förseningsplats',
        field: 'latestDelayPlace',
        align: 'left',
        sortable: true,
      },
      {
        name: 'vehicle',
        label: 'Vagn',
        field: 'vehicle',
        align: 'left',
        sortable: true,
      },
      {
        name: 'vehicleType',
        label: 'Vagntyp',
        field: 'vehicleType',
        align: 'left',
        sortable: true,
      },
      {
        name: 'performedAt',
        label: 'Utförd',
        field: 'performedAt',
        align: 'left',
        sortable: true,
      },
      {
        name: 'performedBy',
        label: 'Utförd av',
        field: 'performedBy',
        align: 'left',
        sortable: true,
      },
    ]

    watch(
      date,
      (period) => {
        if (!period) return

        if (typeof period === 'string') {
          fetchRegistrations({ from: period, to: period })
        } else {
          fetchRegistrations(period)
        }
      },
      {
        immediate: true,
      }
    )

    const dateString = computed(() => {
      if (!date.value) return ''
      if (date.value.from && date.value.to) {
        return `${date.value.from} - ${date.value.to}`
      }
      return date.value
    })

    const tableStyle = computed(() => {
      return {
        height: 'calc(100vh - 100px)',
      }
    })

    const rows = computed(() => {
      const rows = registrations.value.map((registration) => ({
        ...registration,
        arrival: format(new Date(registration.arrival), 'yyyy-MM-dd HH:mm'),
        performedAt: registration.performedAt
          ? format(new Date(registration.performedAt), 'yyyy-MM-dd HH:mm')
          : '',
      }))

      return orderBy(rows, ['arrival'], ['asc'])
    })

    const exportSheet = computed(() => {
      return [
        {
          name: 'Registreringar',
          data: rows.value.map((row: TransformedRegistration) => {
            return columns.reduce<ExportData>((acc, col) => {
              acc[col.label] = row[col.field]
              return acc
            }, {})
          }),
        },
      ]
    })

    function onRowClick(e: Event, row: Registration) {
      selectedRegistration.value = row
      showDetailsModal.value = true
    }

    function onDateModelChange(
      v: { from: string; to: string } | null | string
    ) {
      if (!v) return

      showDateDialog.value = false
    }

    return {
      onDateModelChange,
      selectedRegistration,
      onRowClick,
      showDetailsModal,
      registrationLoading,
      columns,
      rows,
      tableStyle,
      filter,
      date,
      showDateDialog,
      exportSheet,
      dateString,
    }
  },
})
