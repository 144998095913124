
import { defineComponent } from 'vue'
import DeviationsTable from './components/DeviationsTable.vue'

export default defineComponent({
  name: 'Deviations',

  components: { DeviationsTable },

  setup() {
    return {}
  },
})
