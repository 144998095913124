import { CreateUser, getUsers, postUser, removeUser } from '@/api/user.api'
import { User } from '@/types/user'
import { Ref, ref } from 'vue'

interface UseUserMasterdata {
  data: Ref<User[]>
  deleteing: Ref<boolean>
  loading: Ref<boolean>
  createing: Ref<boolean>
  getAll: () => Promise<User[]>
  createUser: (payload: CreateUser) => Promise<User>
  deleteUser: (uuid: string) => Promise<void>
}

const data = ref<User[]>([])

export const useUserMasterdata = (): UseUserMasterdata => {
  const loading = ref(false)
  const deleteing = ref(false)
  const createing = ref(false)

  const getAll = async () => {
    loading.value = true
    const res = await getUsers()
    data.value = res.data
    loading.value = false
    return res.data
  }

  const deleteUser = async (uuid: string) => {
    deleteing.value = true
    const res = await removeUser(uuid)
    data.value = data.value.filter((x) => x.uuid !== uuid)
    deleteing.value = false
    return res.data
  }

  const createUser = async (payload: CreateUser) => {
    createing.value = true
    const { data: user } = await postUser(payload)
    data.value.push(user)
    createing.value = false
    return user
  }
  return {
    data,
    loading,
    getAll,
    deleteUser,
    deleteing,
    createing,
    createUser,
  }
}
