import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'
import { ref, Ref } from 'vue'
import jwt_decode from 'jwt-decode'

interface LoginModel {
  email: string
  password: string
}

interface ResultToken {
  email: string
  user_id: string
}

type User = ResultToken | null

interface UseUser {
  user: Ref<User>
  signOutLoading: Ref<boolean>
  signInLoading: Ref<boolean>
  signInErrorMessage: Ref<SignInErrorMessage>
  signOut: () => Promise<void>
  signIn: (loginModel: LoginModel) => Promise<User>
  checkSignedIn: () => Promise<boolean>
  getToken: () => string | null
}

interface SignInError {
  code?: string
}

interface SignInErrorMessages {
  [error: string]: string
}

type SignInErrorMessage = string | null

const config = {
  apiKey: 'AIzaSyBVeAtgPCo2P358eyTIM_j631WUAwqRlLM',
  authDomain: 'iss-facility-services-ab.firebaseapp.com',
}

const signInErrors: SignInErrorMessages = {
  'auth/user-not-found': 'Det finns ingen användare med denna e-mail!',
  'auth/wrong-password': 'Fel lösenord!',
  any: 'Det gick inte logga in, försök igen.',
}

const user = ref<User>(null)
const signInErrorMessage = ref<SignInErrorMessage>(null)
const signInLoading = ref(false)
const signOutLoading = ref(false)

const app = firebase.initializeApp(config)

const TOKEN_NAME = 'iss_nattag_token'

export const useUser = (): UseUser => {
  async function setToken(userCredential: firebase.User) {
    if (!userCredential) {
      user.value = null
      window.localStorage.removeItem(TOKEN_NAME)
      return
    }
    const result = await userCredential.getIdTokenResult()
    user.value = jwt_decode(result.token)
    window.localStorage.setItem(TOKEN_NAME, result.token)
  }

  function signIn(loginModel: LoginModel): Promise<User> {
    signInErrorMessage.value = null
    signInLoading.value = true
    return new Promise((resolve, reject) => {
      app
        .auth()
        .signInWithEmailAndPassword(loginModel.email, loginModel.password)
        .then(async (userCredential) => {
          if (userCredential.user) {
            await setToken(userCredential.user)
            resolve(user.value)
          } else {
            reject()
          }
        })
        .catch((error: SignInError) => {
          let errorKey = 'any'
          if (error.code) {
            errorKey = error.code as keyof SignInError
          }

          signInErrorMessage.value = signInErrors[errorKey]

          reject(signInErrorMessage.value)
        })
        .finally(() => {
          signInLoading.value = false
        })
    })
  }

  async function checkSignedIn(): Promise<boolean> {
    try {
      await new Promise((resolve, reject) =>
        app.auth().onAuthStateChanged(async (userCredential) => {
          if (userCredential) {
            await setToken(userCredential)

            resolve(user.value)
          } else {
            reject()
          }
        })
      )
      return true
    } catch (error) {
      return false
    }
  }

  function signOut() {
    signOutLoading.value = true
    return app
      .auth()
      .signOut()
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        signOutLoading.value = false
        router.push('/sign-in')
      })
  }

  function getToken() {
    return window.localStorage.getItem(TOKEN_NAME)
  }

  return {
    signOutLoading,
    user,
    signIn,
    signOut,
    checkSignedIn,
    signInErrorMessage,
    signInLoading,
    getToken,
  }
}
