export const required = (val: string | null): boolean | string =>
  Boolean(val && val.length > 0) || 'Obligatorisk'

export const minCharactersRule =
  (minChar: number) =>
  (val: string | number | null): boolean | string =>
    Boolean(val && val.toString().length >= minChar) ||
    `Måste minst vara ${minChar} tecken`

export const maxCharactersRule =
  (max: number) =>
  (val: string | number | null): boolean | string =>
    Boolean(val && val.toString().length <= max) || `Får max vara ${max} tecken`

export const email = (val: string | null): boolean | string => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!val || !regex.test(val)) {
    return `Måste vara en gilltig e-mail`
  }

  return true
}

export const requiredArray = (val: []): boolean | string => {
  if (!val.length) {
    return `Ett val måste väljas`
  }

  return true
}
