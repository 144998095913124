import { instance } from '@/services/iss-nattag-api.service'
import { Deviation } from '@/types/deviation'
import { AxiosResponse } from 'axios'

const URL = 'deviation'

interface DatePeriod {
  from: string
  to: string
}

export const getDeviations = (
  period: DatePeriod
): Promise<AxiosResponse<Deviation[]>> =>
  instance.get(`${URL}`, {
    params: {
      ...period,
    },
  })

export const updateDeviation = (
  deviationUuid: string,
  deviationFormBody: Partial<Deviation>
): Promise<AxiosResponse<Deviation>> =>
  instance.put(`${URL}/${deviationUuid}`, deviationFormBody)
