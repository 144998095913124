
import { computed, defineComponent } from 'vue'
import { useUploadFile } from '@/compositions/useUploadFile'
import { format } from 'date-fns'

export default defineComponent({
  name: 'UploadFileLog',

  setup() {
    const { logLoading, log, fetchImportLog } = useUploadFile()

    fetchImportLog()

    const tableStyle = computed(() => {
      return {
        width: 'calc(100vw - 400px)',
        borderLeft: '1px solid rgba(0,0,0,0.2)',
        height: 'calc(100vh - 100px)',
      }
    })

    const columns = [
      {
        name: 'created',
        label: 'Skapad',
        field: 'created',
        align: 'left',
        sortable: true,
      },
      {
        name: 'Skapad av',
        label: 'Email',
        field: 'email',
        align: 'left',
        sortable: true,
      },
      {
        name: 'fileName',
        label: 'Filnamn',
        field: 'fileName',
        align: 'left',
        sortable: true,
      },
      {
        name: 'periodStart',
        label: 'Period start',
        field: 'periodStart',
        align: 'left',
        sortable: true,
      },
      {
        name: 'periodEnd',
        label: 'Period slut',
        field: 'periodEnd',
        align: 'left',
        sortable: true,
      },
      {
        name: 'createdTrains',
        label: 'Skapade tåg',
        field: 'createdTrains',
        align: 'left',
        sortable: true,
      },
      {
        name: 'deletedTrains',
        label: 'Borttagna tåg',
        field: 'deletedTrains',
        align: 'left',
        sortable: true,
      },
    ]

    const rows = computed(() => {
      return log.value.map((logItem) => ({
        ...logItem,
        created: format(new Date(logItem.created), 'yyyy-MM-dd HH:mm'),
        periodStart: format(new Date(logItem.periodStart), 'yyyy-MM-dd HH:mm'),
        periodEnd: format(new Date(logItem.periodEnd), 'yyyy-MM-dd HH:mm'),
      }))
    })

    return { columns, rows, tableStyle, logLoading }
  },
})
