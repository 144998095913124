import { instance } from '@/services/iss-nattag-api.service'
import { User } from '@/types/user'
import { AxiosResponse } from 'axios'

const URL = 'user'

export interface CreateUser {
  uuid: string
  email: string
  password: string
  roles: string[]
}

export const getUsers = (): Promise<AxiosResponse<User[]>> =>
  instance.get(`${URL}`)

export const removeUser = (uuid: string): Promise<AxiosResponse<void>> =>
  instance.delete(`${URL}/${uuid}`)

export const postUser = (payload: CreateUser): Promise<AxiosResponse<User>> =>
  instance.post(`${URL}`, payload)
