
import { computed, defineComponent, PropType, ref } from 'vue'
import format from 'date-fns/format'
import DeviationCheckDialog from './DeviationCheckDialog.vue'
import { Deviation } from '@/types/deviation'

export default defineComponent({
  name: 'DeviationDetailsModal',

  components: {
    DeviationCheckDialog,
  },

  emits: ['close'],

  props: {
    deviation: Object as PropType<Deviation>,
  },

  setup(props, { emit }) {
    const showConfirmDeviationModal = ref(false)

    function close() {
      emit('close')
    }

    const photoSrc = computed(() => {
      return props.deviation?.photos[props.deviation?.photos.length - 1]?.url
    })

    function formatDate(date: string) {
      return format(new Date(date), 'yyyy-MM-dd HH:mm')
    }

    function onComplete() {
      showConfirmDeviationModal.value = true
    }

    function onConfirmCompleteDeviation() {
      showConfirmDeviationModal.value = false
      close()
    }

    return {
      photoSrc,
      close,
      formatDate,
      onComplete,
      showConfirmDeviationModal,
      onConfirmCompleteDeviation,
    }
  },
})
